import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'

// import LoadMore from './LoadMore'
import { findBreakPoint } from '../../functions/Utils'
import WRE from "../../images/asset owner companies/w.re.png"
import GPE from "../../images/asset owner companies/GPE_blue.png"
import BritishLand from "../../images/asset owner companies/British_Land_logo_blue.png"
import Derewent from "../../images/asset owner companies/derewent_London_blue.png"
import Grosvenor from "../../images/asset owner companies/Grosvenor_blue.png"
import Mactaggart from "../../images/asset owner companies/Mactaggart_blue.png"
import Maurice from "../../images/asset owner companies/Maurice_blue.png"
import Northstar from "../../images/asset owner companies/northstar_blue.png"
import Crown from "../../images/asset owner companies/The_crown_estate_blue.png"
import Topland from "../../images/asset owner companies/Topland_blue.png"
import CanaryWharf from "../../images/asset owner companies/Canary_Wharf.png"
import RiversideCap from "../../images/asset owner companies/Riverside_cap.png"

const SLICE_SIZE = 6 * 4
const MOBILE_SLICE_SIZE = 3 * 4

// For Netlify's Issues
const activeWindow = typeof window !== "undefined" ? window : null

// Need to add mobile scrolling carousel

const variants = {
    STANDARD: 'standard',
    CONCISE: 'concise',
}

const FeaturedAssetOwners = ({ variant }) => {

    // ---- Constants ----

    const imgs = [
        {
            companyName: "W.RE",
            src: WRE,
            id: 1,
        },
        {
            companyName: "GPE.",
            src: GPE,
            id: 2,
        },
        {
            companyName: "Grosvenor",
            src: Grosvenor,
            id: 3,
        },
        {
            companyName: "Maurice Investments",
            src: Maurice,
            id: 4,
        },
        {
            companyName: "The Crown Estate",
            src: Crown,
            id: 5,
        },
        {
            companyName: "Topland Group of Companies",
            src: Topland,
            id: 6,
        },
        {
            companyName: "Northstar Capital",
            src: Northstar,
            id: 7,
        },
        {
            companyName: "Derewent London",
            src: Derewent,
            id: 8,
        },
        {
            companyName: "Mactaggart Family & Partners",
            src: Mactaggart, 
            id: 9,
        },
        {
            companyName: "British Land",
            src: BritishLand,
            id: 10,
        },
        {
            companyName: "Canary Wharf",
            src: CanaryWharf,
            id: 11,
        },
        {
            companyName: "Riverside Capital",
            src: RiversideCap,
            id: 12,
        }
    ]

    
    const renderFeaturedCompanies = (imgs) => {
        return imgs.map(img => {

            return (
                <div
                    key={img.id}
                    className="mx-auto px-1 my-6 w-1/3 md:w-1/6 self-center px-6 md:px-6 animated fadeInUp"
                >
                    <div
                        role="img"
                        aria-label='Featured_Company'
                        style={{
                            background: `url(${img.src})`,
                            backgroundSize: 'contain',
                            backgroundRepeat:  'no-repeat',
                            backgroundPosition: '50% 50%',
                            maxWidth: 100,
                            height: 50
                        }}
                    >

                    </div>
                </div>)
        })
    }


    return (
        <>
            <div className="flex justify-between flex-wrap text-center transition-slow mb-6 mx-auto max-w-6xl">
                {renderFeaturedCompanies(imgs)}
            </div>
           
        </>
    )
}

FeaturedAssetOwners.propTypes = {
    variant: PropTypes.oneOf(Object.values(variants)),
}

FeaturedAssetOwners.defaultProps = {
    variant: variants.STANDARD,
}

export default FeaturedAssetOwners
