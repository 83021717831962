import React from 'react'
import PropTypes from 'prop-types'
import Arrow from '../../images/ui/arrow.svg'

const LoadMore = props => {

    const { handleLoadMore, loadedAll, loading, arrow, text, className, id } = props;

    if (loadedAll) return ""

    return (
        <div className={`mx-auto text-center ${className}`} >
            <div
                id={id}
                onClick={() => handleLoadMore()}
            >
                {!loading ? <div
                    className="text-sm hover:font-bold hover:text-secondary cursor-pointer border-primary flex-auto p-4 inline-block uppercase"
                    tabIndex={0}
                    role='button'>
                    { text }

                    { arrow ?
                    <img
                        id="yellow-arrow"
                        style={{ transform: 'rotate(-180deg)' }}
                        className="inline-block ml-2 text-secondary mb-1"
                        src={Arrow}
                        alt="Arrow Icon"
                    />: ""}
                </div> : "Loading..."}
            </div>
        </div>
    )
}


LoadMore.defaultProps = {
    text: "Load More",
    arrow: true,
    id: undefined,
}

LoadMore.propTypes = {
    handleLoadMore: PropTypes.func.isRequired,
    loadedAll: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    arrow: PropTypes.bool,
    text: PropTypes.string,
    id: PropTypes.string,
}

export default LoadMore
