import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import LoadMore from './LoadMore'
import { findBreakPoint } from '../../functions/Utils'

const SLICE_SIZE = 6 * 4
const MOBILE_SLICE_SIZE = 3 * 4

// For Netlify's Issues
const activeWindow = typeof window !== "undefined" ? window : null

// Need to add mobile scrolling carousel

const variants = {
    STANDARD: 'standard',
    CONCISE: 'concise',
}

const Featured = ({ variant }) => {

    // ---- Constants ----

    // Fetches the initial data
    const data = useStaticQuery(graphql`
        query featured {
            allContentfulFeaturedCompanies {
                edges {
                    node {
                        id
                        topLevel
                        order
                        companyLogo {
                            id
                            file {
                                url
                            }
                            fluid(maxWidth: 100){
                                ...GatsbyContentfulFluid
                            }
                            title
                        }
                    }
                }
            }
        }
    `)

    // List of all companies (top-level come first)
    const allCompanies = data.allContentfulFeaturedCompanies.edges
        .filter(({ node }) => node.order)
        .sort((a, b) => a.node.order - b.node.order)

    const [sliceSize, setSliceSize] = useState()

    // State for button (not used)
    const loading = false

    // ---- State ----

    // List of companies to render
    const [companies, setCompanies] = useState([])

    // Toggles loaded all
    const [loadedAll, setLoadedAll] = useState(false)

    // ---- Functions ----

    const handleClick = () => {

        if (loadedAll)
            return ""

        else {
            // Extract a larger slice
            const toDisplay = allCompanies.slice(0, companies.length + sliceSize)

            // Update state
            setCompanies(toDisplay)
            setLoadedAll(toDisplay.length === allCompanies.length)
        }
    }

    const renderFeaturedCompanies = (companies) => {
        return companies.map(company => {
            const node = company.node

            // Handles the different data formats
            // One coming from the client request (contentfulAPI)
            // the other coming from the build time request
            const src = node ?
                node.companyLogo.file.url :
                company.fields.companyLogo.fields.file.url

            return (
                <div
                    key={company[company.node ? "node" : "sys"].id}
                    className="mx-auto px-1 my-6 w-1/3 md:w-1/6 self-center px-6 md:px-6 animated fadeInUp"
                >
                    <div
                        role="img"
                        aria-label='Featured_Company'
                        style={{
                            background: `url(${src})`,
                            backgroundSize: 'contain',
                            backgroundRepeat:  'no-repeat',
                            backgroundPosition: '50% 50%',
                            maxWidth: 100,
                            height: 50
                        }}
                    >

                    </div>
                </div>)
        })
    }

    const handleResize = () => {
        const updatedSliceSize = findBreakPoint(activeWindow.innerWidth) > 2 ? SLICE_SIZE : MOBILE_SLICE_SIZE
        const toDisplay = allCompanies.slice(0, updatedSliceSize)

        setSliceSize(updatedSliceSize)
        setCompanies(toDisplay)
        setLoadedAll(toDisplay.length === allCompanies.length)
    }

    useEffect(() => {
        if (activeWindow) {
            handleResize()
            activeWindow.addEventListener('resize', handleResize)

            return () => {
                activeWindow.removeEventListener('resize', handleResize)
            }
        }
    }, [])

    return (
        <>
            <div className="flex justify-between flex-wrap text-center transition-slow my-6 mx-auto max-w-6xl">
                {renderFeaturedCompanies(companies.slice(0, sliceSize / 2))}
            </div>
            <h2 className="text-center mt-8 px-6 text-3xl">
                {`Trusted ${variant === variants.STANDARD ? 'globally ' : ''}by `}
                <span className="border-b-2 border-secondary font-bold">over 600 scaleups and unicorns</span>
            </h2>
            {variant === variants.STANDARD && (
                <p className="text-center mt-4 mb-8 px-6 text-lg">
                    Some are fourth or fifth timers with Kontor. Most we’ve earned through word of mouth.
                </p>
            )}
            <div className="flex justify-between flex-wrap text-center transition-slow mt-6 mx-auto max-w-6xl">
                {renderFeaturedCompanies(companies.slice(sliceSize / 2))}
            </div>
            {variant === variants.STANDARD && (
                <LoadMore
                    text="See More"
                    handleLoadMore={handleClick}
                    loading={loading}
                    loadedAll={loadedAll}
                />
            )}
        </>
    )
}

Featured.propTypes = {
    variant: PropTypes.oneOf(Object.values(variants)),
}

Featured.defaultProps = {
    variant: variants.STANDARD,
}

export default Featured
