import React, { Children, Component, cloneElement } from 'react'

export default class ParallaxContainer extends Component {

    constructor(props) {
        super(props)
        this.containerRef = React.createRef()

        this.state = {
                top: 0
            }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);

        if (this.state.top === 0) this.handleScroll()
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    handleScroll = () => {

        const container = this.containerRef.current ? this.containerRef.current : null
        const activeWindow = typeof window !== 'undefined' ? window : null

        if (!container && !activeWindow) return ""
        const containerBox = container ? container.getBoundingClientRect() : {};
        const { top, bottom, height } = containerBox;

        if ((top && bottom) && top - activeWindow.innerHeight < 0 && bottom > 0) {
            const movement = (height-top) / 10
            this.setState({top: movement})
        }

    }

    render() {

        const  { weighting, reverse } = this.props;

        // How far to move
        const movement = reverse ?  - (this.state.top * weighting) : (this.state.top * weighting)

        return (
            <div
                ref={this.containerRef}
                className={"relative flex " + this.props.className}
                style={{...this.props.style}}>
                {Children.map(this.props.children, (element, index) => {
                    return cloneElement(
                        element,
                        {
                            ...element.props,
                            style: {
                                ...element.props.style,
                                marginTop: (index + 1) % 2 === 0 ? -movement : movement,
                                backfaceVisibility: 'hidden',
                                transform: 'translate3d(0, 0, 0)',
                            }
                        },
                    );
                })}
            </div>
        )
    }
}

ParallaxContainer.defaultProps = {
    weighting: 1,
    early: 0,
    reverse: false
};
